import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги - Auto Drive
			</title>
			<meta name={"description"} content={"Електричні характеристики, щоразу на висоті - ознайомтеся з послугами Auto Drive"} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги - Auto Drive"} />
			<meta property={"og:description"} content={"Електричні характеристики, щоразу на висоті - ознайомтеся з послугами Auto Drive"} />
			<meta property={"og:image"} content={"https://quantirohub.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quantirohub.com/img/360464.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quantirohub.com/img/360464.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quantirohub.com/img/360464.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quantirohub.com/img/360464.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quantirohub.com/img/360464.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quantirohub.com/img/360464.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 120px 0" sm-padding="60px 0 60px 0" quarkly-title="Cards-8" background= "#f9f2f0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="flex-start"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 70px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					text-align="left"
					sm-font="normal 700 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					width="50%"
					lg-width="100%"
				>
					Наші послуги
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
						background= "#fff"
					>
						<Box>
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Експертні послуги, що пропонуються
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Розширена діагностика: Використання найсучаснішого обладнання для швидкої діагностики електричних проблем, що забезпечує точне та ефективне обслуговування.
<br/><br/>
Електричний ремонт: Експертні послуги з ремонту всіх проблем з електрообладнанням автомобіля, від базових проблем з електропроводкою до складних несправностей у схемах.

							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
						background= "#fff"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								
Спеціалізовані рішення

							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Індивідуальне електрообладнання: Послуги з монтажу на замовлення, включаючи аудіосистеми, навігаційні системи та індивідуальні світлові рішення, розроблені відповідно до специфікацій вашого автомобіля.
<br/><br/>
Перевірка та заміна акумуляторів: Комплексна перевірка акумулятора та високоякісна заміна для забезпечення надійності вашого автомобіля.

							</Text>
						</Box>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						padding="32px 32px 32px 32px"
						border-width="1px"
						border-style="solid"
						border-radius="24px"
						border-color="--color-darkL2"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						min-height="450px"
						justify-content="space-between"
						margin="0px 0px 16px 0px"
						md-min-height="350px"
						background= "#fff"
					>
						<Box md-width="100%">
							<Text
								margin="0px 0px 20px 0px"
								color="--dark"
								font="--headline3"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								width="100%"
							>
								Технічне обслуговування та модернізація
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								color="--dark"
								font="--lead"
								lg-margin="0px 0px 50px 0px"
								sm-margin="0px 0px 30px 0px"
								flex="1 0 auto"
								letter-spacing="-0.5px"
							>
								Регулярне технічне обслуговування електрообладнання: Профілактичне обслуговування для підтримання електричних систем вашого автомобіля в оптимальному стані.
<br/><br/>
Технологічна модернізація: Сучасна модернізація старих автомобілів, включаючи сучасне освітлення, системи зарядки тощо.

							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1" background= "#f9f2f0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://quantirohub.com/img/7.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://quantirohub.com/img/8.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Дізнайтеся про повний спектр наших послуг і про те, як ми можемо підвищити продуктивність вашого автомобіля:
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.FooterMenu />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});